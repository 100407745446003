import React, {RefObject} from "react";
import Provider from "../providers/Provider";
import {createEvent, createStore} from "effector";
import Sounds from "../Sounds";
import patch from "./monkeyPatchFetch";

import SDGameConfig from "space-dangers-dep/build/index.json"
import TestGameConfig from "test-dep/build/index.json"

import SDSoundConfig from "space-dangers-dep/sounds/config.json"
import TestSoundConfig from "test-dep/sounds/config.json"

import SDL18nEnConfig from "space-dangers-dep/l18n/en.json"
import SDL18nRuConfig from "space-dangers-dep/l18n/ru.json"

import TestL18nEnConfig from "test-dep/l18n/en.json"
import TestL18nRuConfig from "test-dep/l18n/ru.json"


const gameConfigs: { [key: string]: { [otherKey: string]: string } } = {
    "spaceDangers": SDGameConfig,
    "test": TestGameConfig
}

const soundConfigs: { [key: string]: { [otherKey: string]: string } } = {
    "spaceDangers": SDSoundConfig,
    "test": TestSoundConfig
}


const l18configs: { [key: string]: { [key: string]: { [otherKey: string]: string } } } = {
    "spaceDangers": {
        "en": SDL18nEnConfig,
        "ru": SDL18nRuConfig,
    },
    "test": {
        "en": TestL18nEnConfig,
        "ru": TestL18nRuConfig,
    },
}


declare global {
    interface Window {
        createUnityInstance: any;
    }

    interface Window {
        ua: UnityAdmin;
    }
}

export default class UnityAdmin {
    // used from Unity .jslib, don't touch!
    public provider: Provider
    public gameConfig: { [otherKey: string]: string } = {data: '', framework: '', code: '', product: '', version: ''};

    constructor(provider: Provider) {
        this.provider = provider;
    }

    public UnityCanvasRef: RefObject<HTMLCanvasElement> = React.createRef<HTMLCanvasElement>();
    public Sounds: Sounds | undefined;
    public UnityInstance: any;
    public UnityResizeCanvas: any;

    // effector
    public $coverIsActive = createStore(true);
    public $coverProgress = createStore(0);
    public SetCoverIsActive = createEvent<boolean>()
    public ProgressEvent = createEvent<number>()

    InitializeSoundsByGameName(game: string) {
        this.Sounds = new Sounds(game);
        this.Sounds.Initialize();
    }

    InitializeSoundsByConfig(game: string, config: { [key: string]: string }) {
        this.Sounds = new Sounds(game);
        this.Sounds.config = config;
        this.Sounds.Initialize();
    }

    InitializeEffector() {
        this.$coverIsActive.on(
            this.SetCoverIsActive,
            (f, t) => {
                console.warn("[UnityApp] SetCoverIsActive:" + t);
                return t;
            }
        );

        this.$coverProgress.on(
            this.ProgressEvent,
            (f, t) => {
                return t;
            }
        );
    }

    public HideLoader() {
        console.warn("[UnityApp] HideLoader");
        this.SetCoverIsActive(false);
    }

    public IsBrowserMobile(): boolean {
        console.warn("[UnityApp] IsBrowserMobile");
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    public GetAgent(): string {
        console.warn("[UnityApp] GetAgent");
        return navigator.userAgent;
    }

    _translation: any

    async FetchTranslation(game: string) {
        console.warn("[UnityApp] FetchTranslation: " + game);
        let lang = this.provider.GetLanguage() || "en";

        if (game in l18configs && lang in l18configs[game]) {
            this._translation = JSON.stringify({"translations": l18configs[game][lang]});
        } else {
            let response = await fetch(`/${game}/l18n/${lang}.json`);

            if (response.status === 200) {
                var data = await response.json();
                this._translation = JSON.stringify({"translations": data});
            } else {
                var data = await response.json();
                this._translation = JSON.stringify({"translations": {}});
            }
        }
    }

    public GetTranslation(game: string) {
        console.warn("[UnityApp] GetTranslation: " + game);
        return this._translation || "{\"translations\":{}}";
    }

    public GetPlatform(): string {
        console.warn("[UnityApp] GetPlatform");
        return this.provider.GetPlatform() || "unknown";
    }

    public PlaySound(sound: string, volume: number) {
        console.warn(`[UnityApp] PlaySound: sound ${sound}, volume: ${volume}`,)
        this.Sounds?.Play(sound, volume);
    }

    public PlayLoop(sound: string, volume: number) {
        console.warn(`[UnityApp] PlayLoop: sound ${sound}, volume: ${volume}`,)
        this.Sounds?.PlayLoop(sound, volume);
    }

    public StopLoop() {
        console.warn(`[UnityApp] StopLoop`,)
        this.Sounds?.StopLoop();
    }

    public AttachLoaderScript(id: string, game: string) {
        let loaderScript = document.querySelector("#loader-script")

        if (loaderScript === null) {
            const script = document.createElement('script');
            script.src = `/${game}/build/Build/build.loader.js`;
            script.id = "loader-script";
            script.type = "application/javascript";
            script.async = false;
            script.addEventListener("load", () => this.OnLoadScript(game));
            document.body.appendChild(script);
        }
    }

    public OnLoadScript(game: string) {
        if (this.UnityCanvasRef && this.UnityCanvasRef.current)
            this.StartLoad(game, this.UnityCanvasRef.current);

        this.UnityResizeCanvas = function () {
            console.log("try resize");
        };
    }

    public async Initialize(game: string) {
        this.InitializeEffector();
        this.InitializeSoundsByConfig(game, soundConfigs[game]);

        await this.FetchTranslation(game);

        this.AttachLoaderScript('loader-script', game);
    }

    private async FakeProgress() {

        this._fakeProgress = 0;
        const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

        var next = Math.random() * 200;
        await sleep(next);
        this._fakeProgress = Math.random() * 0.2;
        this.UpdateProgress();

        next = Math.random() * 200;
        await sleep(next);
        this._fakeProgress = 0.2;
        this.UpdateProgress();

        next = Math.random() * 200;
        await sleep(next);
        this._fakeProgress = 0.2 + Math.random() * 0.2;
        this.UpdateProgress();

        next = Math.random() * 200;
        await sleep(next);
        this._fakeProgress = 0.4;
        this.UpdateProgress();

        next = Math.random() * 1000;
        await sleep(next);
        this._fakeProgress = 0.4 + Math.random() * 0.2;
        this.UpdateProgress();

        next = Math.random() * 1000;
        await sleep(next);
        this._fakeProgress = 0.6;
        this.UpdateProgress();

        next = Math.random() * 2000;
        await sleep(next);
        this._fakeProgress = 0.6 + Math.random() * 0.2;
        this.UpdateProgress();

        next = Math.random() * 500;
        await sleep(next);
        this._fakeProgress = 0.8;
        this.UpdateProgress();

        next = Math.random() * 2000;
        await sleep(next);
        this._fakeProgress = 0.8 + Math.random() * 0.2;
        this.UpdateProgress();

        next = Math.random() * 100;
        await sleep(next);
        this._fakeProgress = 1;
        this.UpdateProgress();
    }

    _fakeProgress = 0;
    _realProgress = 0;

    private UpdateProgress() {
        var fakeProgress = Math.min((this._realProgress + this._fakeProgress) / 2, 0.99);
        this.ProgressEvent(fakeProgress);
    }

    private OnProgress(progress: number) {
        this._realProgress = progress;
        this.UpdateProgress();
    }

    public async StartLoad(game: string, canvasElement: HTMLCanvasElement) {
        this.provider.LoadingStart();

        const buildUrl = `/${game}/build/Build`

        if (game in gameConfigs) {
            this.gameConfig = gameConfigs[game]
            console.log(this.gameConfig)
            console.log(gameConfigs[game])
        } else {
            let response = await fetch(`/${game}/build/index.json`);
            this.gameConfig = await response.json();
        }

        let codeUrl = `${buildUrl}/${this.gameConfig.code}`;
        let dataUrl = `${buildUrl}/${this.gameConfig.data}`;

        // if ( game === "test" ) {
        //     patch();
        // }

        patch();

        let config = {
            dataUrl: dataUrl,
            frameworkUrl: `${buildUrl}/${this.gameConfig.framework}`,
            codeUrl: codeUrl,
            streamingAssetsUrl: "StreamingAssets",
            companyName: "NDA",
            productName: `${buildUrl}/${this.gameConfig.product}`,
            productVersion: `${buildUrl}/${this.gameConfig.version}`,
            // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
            devicePixelRatio: window.devicePixelRatio // Uncomment this to override low DPI rendering on high DPI displays.
        };

        window.createUnityInstance(
            canvasElement,
            config,
            (p: number) => this.OnProgress(p)
        ).then(
            (unityInstance: any) => this.OnLoad(unityInstance, canvasElement)
        );

        this.FakeProgress().then(() => this.UpdateProgress());
    }

    public OnLoad(unityInstance: any, canvasElement: HTMLCanvasElement) {
        this.UnityInstance = unityInstance;
        this.UnityResizeCanvas();
        this.provider.LoadingStop();
        console.warn("[UnityApp] Loaded")
    }
}