import {Howl} from "howler";

export default class Sounds {
    _gameName: string = "";
    public config: {[key: string]: string} | null = null;

    constructor( game: string ) {
        this._gameName = game;
    }

    sounds: {[key: string]: Howl} = {}

    async _loadSounds() {
        if ( !this.config ) {
            let response = await fetch(`/sounds/${this._gameName}/config.json`);
            this.config = await response.json();
        }

        if ( this.config )
            Object.entries(this.config).forEach(
                (pair) => {
                    const path = pair[0];
                    const alias = pair[1];

                    this.sounds[alias] = new Howl(
                        {
                            src: [path],
                            loop: alias === "loop",
                            volume: alias === "loop" ? 0.3 : 0.5,
                            preload: true,
                            // autoplay: alias === "loop"
                        }
                    );
                }
            );
    }

    Initialize() {
        this._loadSounds()
        window.addEventListener('blur', () => this.WhenNotFocused() );
        window.addEventListener('focus', () => this.WhenFocused() );
    }

    private WhenFocused() {
        console.log("When focused");

        if ( this._loopId ) {
            this._loopId = this.sounds[this._loopName].play();
        }
    }

    private WhenNotFocused() {
        console.log("When not focused");

        if ( this._loopId ) {
            this.sounds[this._loopName].stop( this._loopId );
        }
    }

    public Play(name: string, volume: number) {
        try {
            this.sounds[name].volume(volume);
            this.sounds[name].loop( false );
            this.sounds[name].play();
        }
        catch (e) {
            console.warn(e);
        }
    }

    private _loopId = 0;
    private _loopName = "";

    public PlayLoop(name: string, volume: number = 1): void {
        try {
            this.sounds[name].volume(volume);

            if ( this._loopName !== name && !!this._loopName ) {
                this.StopLoop();
            }

            if ( !this._loopId ) {
                this.sounds[name].loop( true );
                this._loopId = this.sounds[name].play();
                this._loopName = name;
            }
        }
        catch (e) {
            console.warn(e);
        }
    }

    public StopLoop() {
        try {
            if ( this._loopId ) {
                this.sounds[this._loopName].stop( this._loopId );
                this._loopId = 0;
            }
        }
        catch (e) {
            console.warn(e);
        }
    }

    public StopAllSounds() {
        this.StopLoop()
    }
}