import Provider, {LeaderboardRequest} from "./Provider"
import Universal from "./Universal";

declare global {
    interface Window { playdeck: any; }
}

export default class Telegram implements Provider {
    _universal: Universal = new Universal();

    SupportScreenHeightBaking(): boolean {
        return false;
    }

    BottomOffsetForBanners(): number {
        return 0;
    }

    // game loading
    LoadingStart(): void {}
    LoadingStop(): void {}

    GetPlatform(): string {
        return "telegram";
    }

    private _playDeck: Playdeck | undefined;

    // call from js before unity app loaded
    async Initialize(): Promise<void> {
        this._playDeck = window.playdeck;
    }

    async InitializeIAPs(): Promise<void> {}
    async InitializeLeaderboards(): Promise<void> {}

    // call from unity
    ConfirmReady(): void {}
    GameplayResume(): void {}
    GameplayPaused(): void {}
    Congratulations(): void {}

    // auth
    private _userObject: UserObject = {id: "unknown", nickname: "unknown"};
    async LiteAuth(): Promise<void> {
        this._userObject = this._playDeck?.getUser() || {id: "unknown", nickname: "unknown"};
        this._universal.SetPlayerId(this._userObject.id);
    }
    async FullAuth(): Promise<void> {}
    IsAuthorized(): boolean {
        return false;
    }
    GetUniquePlayerId(): string {
        return this._userObject.id;
    }

    private _userData: string = "";
    // profile data
    async FetchPlayerData(): Promise<void> {
        await this._universal.FetchPlayerData();
    }
    IsPlayerDataFetched() {
        return this._universal.IsPlayerDataFetched();
    }

    GetPlayerData(): string {
        return this._universal.GetPlayerData()
    }
    async SetPlayerData(data: string): Promise<void> {
        await this._universal.SetPlayerData(data);
    }

    // preferences
    GetLanguage(): string {
        return this._playDeck?.getUserLocale() || "ru";
    }

    // IAPs
    IsActiveIAPs(): boolean {
        return false;
    }

    async FetchPurchases(): Promise<void> {

    }
    GetPurchases(): string {
        return ""
    }
    async Purchase(purchaseId: string): Promise<void> {}
    async Consume(token: string): Promise<void> {}

    // Banner ads
    async ShowBanner(): Promise<void> {}
    async HideBanner(): Promise<void> {}

    // Rewarded ads
    HasRewardedVideo(): boolean {
        return false;
    }
    async FetchRewardedVideo(): Promise<void> {}
    async ShowRewardedVideo(): Promise<void> {}
    GetRewardedVideoStatus(): string {
        return "reset"
    }
    IsRewardForAdConfirmed(): boolean {
        return this._universal.IsRewardForAdConfirmed();
    }

    // Interstitial ads
    HasInterstitialVideo(): boolean {
        return false;
    }
    async FetchInterstitialVideo(): Promise<void> {}
    async ShowInterstitialVideo(): Promise<void> {}
    GetInterstitialVideoStatus(): string {
        return "reset";
    }

    // Leaderboards
    IsActiveLeaderboards(): boolean {
        return false;
    }
    async FetchLeaderboard(board: string): Promise<void> {}
    GetLeaderboard(): string {
        let fakeEntries = this._universal.FakeFetchLeaderBoards(null);
        let data = this._universal.ParseYandexLeaderboard( fakeEntries );
        return JSON.stringify(data);
    }
    async SetLeaderboardScore(request: LeaderboardRequest): Promise<void> {}

    // Social
    IsActiveFriends(): boolean {
        return false;
    }

    async FriendHelpRequest(text: string): Promise<void> {}

    IsPublishTextResult(): boolean {
        return true;
    }
    IsTextPublished(): boolean {
        return false;
    }
    ResetPublishedText(): void {}
    ResetPublishTextResult(): void {}
    async PublishText( text: string, attachment: string ): Promise<void> {}
}