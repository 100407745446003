import Provider, {LeaderboardRequest} from "./Provider";
import Universal from "./Universal";

declare global {
    interface Window { CrazyGames: any; }
}


interface CrazyGamesUser {
    id: string,
    nickname: string,
    profilePictureUrl: string
}

export default class CrazyGames implements Provider {
    private _universal = new Universal();

    SupportScreenHeightBaking(): boolean {
        return false;
    }

    BottomOffsetForBanners(): number {
        return 160;
    }

    // game loading
    LoadingStart(): void {
        window.CrazyGames.SDK.game.sdkGameLoadingStart();
    }
    LoadingStop(): void {
        window.CrazyGames.SDK.game.sdkGameLoadingStop();
    }


    GetPlatform(): string {
        return "crazygames";
    }
    // call from js before unity app loaded
    async Initialize(): Promise<void> {}
    async InitializeIAPs(): Promise<void> {}
    async InitializeLeaderboards(): Promise<void> {}

    // call from unity
    ConfirmReady(): void {}

    GameplayResume(): void {
        window.CrazyGames.SDK.game.gameplayStart();
    }
    GameplayPaused(): void {
        window.CrazyGames.SDK.game.gameplayStop();
    }
    Congratulations(): void {
        window.CrazyGames.SDK.game.happytime();
    }

    // auth
    private _isAuthorized: boolean = false;
    private _crazyGamesUser: CrazyGamesUser | undefined;

    private async _AuthAsUniversal() {
        await this._universal.LiteAuth();
        this._isAuthorized = true;
        console.log("Universal auth")
    }

    async LiteAuth(): Promise<void> {
        const isAvailable = await window.CrazyGames.SDK.user.isUserAccountAvailable();

        console.log("User account available:", isAvailable);

        if ( isAvailable ) {
            try {
                this._crazyGamesUser = await window.CrazyGames.SDK.user.getUser();
                this._isAuthorized = true;
            }
            catch (e) {
                await this._AuthAsUniversal();
            }
        }
        else {
            await this._AuthAsUniversal()
        }

        console.log("User account data:", this._crazyGamesUser);
    }
    async FullAuth(): Promise<void> {}
    IsAuthorized(): boolean {
        return this._isAuthorized;
    }
    GetUniquePlayerId(): string {
        if ( this._crazyGamesUser != null ) {
            return this._crazyGamesUser.id;
        }
        else {
            return this._universal.GetUniquePlayerId();
        }
    }

    // profile data
    async FetchPlayerData(): Promise<void> {
        await this._universal.FetchPlayerData();
    }

    IsPlayerDataFetched() {
        return this._universal.IsPlayerDataFetched();
    }

    GetPlayerData(): string {
        return this._universal.GetPlayerData();
    }

    async SetPlayerData(data: string): Promise<void> {
        await this._universal.SetPlayerData(data);
    }

    // preferences
    GetLanguage(): string {
        return "en";
    }

    // IAPs
    IsActiveIAPs(): boolean {
        return this._universal.IsActiveIAPs();
    }
    async FetchPurchases(): Promise<void> {
        await this._universal.FetchPurchases();
    }
    GetPurchases(): string {
        return this._universal.GetPurchases();
    }
    async Purchase(purchaseId: string): Promise<void> {
        await this._universal.Purchase( purchaseId );
    }
    async Consume(token: string): Promise<void> {
        await this._universal.Consume( token );
    }

    // Banner ads
    async ShowBanner(): Promise<void> {
        try {
            const result = await window.CrazyGames.SDK.banner.requestResponsiveBanner("responsive-banner-container");
            console.log("End request responsive banner", result); // result is always undefined when requesting banners
        } catch (e) {
            console.log("Error on request responsive banner", e);
        }
    }
    async HideBanner(): Promise<void> {}

    // Rewarded ads
    private _rewardedStatus = "reset";
    HasRewardedVideo(): boolean {
        return true;
    }
    async FetchRewardedVideo(): Promise<void>{

    }
    async ShowRewardedVideo(): Promise<void> {
        this._rewardedStatus = "reset";

        const callbacks = {
            adFinished: () => {
                this._rewardedStatus = "allowReward";
            },
            adError: (error: any) => {
                this._rewardedStatus = "error";
            },
            adStarted: () =>  {
                this._rewardedStatus = "started";
            }
        };
        window.CrazyGames.SDK.ad.requestAd("rewarded", callbacks);
    }

    GetRewardedVideoStatus(): string {
        return this._rewardedStatus;
    }

    IsRewardForAdConfirmed(): boolean {
        return this._universal.IsRewardForAdConfirmed();
    }

    // Interstitial ads
    private _interstitialStatus = "reset";
    HasInterstitialVideo(): boolean {
        return true;
    }
    async FetchInterstitialVideo(): Promise<void>{

    }
    async ShowInterstitialVideo(): Promise<void> {
        this._interstitialStatus = "reset";

        const callbacks = {
            adFinished: () => {
                this._interstitialStatus = "allowReward";
            },
            adError: (error: any) => {
                this._interstitialStatus = "error";
            },
            adStarted: () =>  {
                this._interstitialStatus = "started";
            }
        };
        window.CrazyGames.SDK.ad.requestAd("midgame", callbacks);
    }

    GetInterstitialVideoStatus(): string {
        return this._interstitialStatus;
    }

    // Leaderboards
    IsActiveLeaderboards(): boolean {
        return this._universal.IsActiveLeaderboards();
    }
    async FetchLeaderboard(board: string): Promise<void> {
        await this._universal.FetchLeaderboard( board );
    }
    GetLeaderboard(): string {
        return this._universal.GetLeaderboard();
    }
    async SetLeaderboardScore(request: LeaderboardRequest): Promise<void> {
        await this._universal.SetLeaderboardScore( request );
    }

    // Social
    IsActiveFriends(): boolean {
        return false;
    }

    async FriendHelpRequest(text: string): Promise<void> {}

    IsPublishTextResult(): boolean {
        return true;
    }
    IsTextPublished(): boolean {
        return false;
    }
    ResetPublishedText(): void {}
    ResetPublishTextResult(): void {}
    async PublishText( text: string, attachment: string ): Promise<void> {}
}