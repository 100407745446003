import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import React from "react";
import GameProviderWrapper from "./GameProvider";

function App() {
    const router = createBrowserRouter(
        [
            {
                path: "/:gameName/",
                element: <GameProviderWrapper/>,
            },
            {
                path: "/spaceDangers/StreamingAssets/",
                element: <Navigate to="/builds/spaceDangers/build/StreamingAssets/" />
            },
            {
                path: "/",
                element: <Navigate to="/spaceDangers/" />
            }
        ]
    );

    return (
        <RouterProvider router={router}></RouterProvider>
    );
}

export default App;