import React from "react";
import VK from "../providers/VK";
import Yandex from "../providers/Yandex";
import CrazyGames from "../providers/CrazyGames";
import Telegram from "../providers/Telegram";
import Universal from "../providers/Universal";
import Provider from "../providers/Provider";
import {Loader} from "./UnityCanvas";
import {useParams} from "react-router";
import GameSDK from "./GameSDK";
import styled from "styled-components";


interface GameProviderProps {
    params: any;
}

interface GameProviderState {

}

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`

class GameProvider extends React.Component<GameProviderProps, GameProviderState> {
    public provider: Provider;
    public gameName: string;

    private _GetSearchParameter(name: string) {
        let sp = new URLSearchParams(window.location.search);
        return sp.get(name);
    }

    private _GetProvider(): Provider {
        var currentUrl = document.referrer;

        if (currentUrl.includes("vk.com")) {
            return new VK();
        } else if (currentUrl.includes("direct.yandex.ru")) {
            return new Universal();
        } else if (currentUrl.includes("yandex.")) {
            return new Yandex();
        } else if (currentUrl.includes("crazygames.") || currentUrl.includes("1001juegos")) {
            return new CrazyGames();
        } else if (this._GetSearchParameter("gameToken") && this._GetSearchParameter("id")) {
            return new Telegram();
        } else {
            return new Universal();
        }
    }

    constructor(props: GameProviderProps) {
        super(props);
        this.provider = this._GetProvider();

        let {gameName} = this.props.params;
        this.gameName = gameName || "unknown";
    }

    private unityGames = [
        "spaceDangers",
        "test",
        "murfics",
    ]

    public render() {
        if (this.unityGames.includes(this.gameName)) {
            return (
                <GameSDK provider={this.provider}>
                    <Loader gameName={this.gameName} provider={this.provider}/>
                </GameSDK>
            )
        } else {
            return (
                <GameSDK provider={this.provider}>
                    <StyledIframe src={"/iframe/" + this.gameName + "/"}></StyledIframe>
                </GameSDK>
            )
        }
    }
}

export default function GameProviderWrapper(props: any) {
    return (
        <GameProvider
            {...props}
            params={useParams()}
        />
    )
}