import Provider, {LeaderboardRequest} from "./Provider";
import Universal from "./Universal";

export default class Yandex implements Provider {
    SupportScreenHeightBaking(): boolean {
        return false;
    }

    BottomOffsetForBanners(): number {
        return 0;
    }

    // game loading
    LoadingStart(): void {
        try {
            this._sendEvent("StartLoadingUnity" );
        }
        catch (e) {

        }
    }

    LoadingStop(): void {
        try {
            this._sendEvent("EndLoadingUnity" );
        }
        catch (e) {

        }
    }

    async postData(url = "", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            }
        );
        return response.json(); // parses JSON response into native JavaScript objects
    }

    _sendEvent( name: string ) {
        let event = {
            "player_id": this.GetUniquePlayerId(),
            "event_name": name,
            "session_number": -1,
            "soft_currency": 0,
            "hard_currency": 0,
            "language": this.GetLanguage(),
            "agent": navigator.userAgent,
            "fx_volume": 0,
            "general_volume": 0,
            "music_volume": 0,
            "game": "Space Dangers"
        }



        this.postData(
            this.analyticsUrl,
            {"events": [event]}
        )
    }

    GetPlatform(): string {
        return "yandex";
    }

    _universal: Universal = new Universal();

    analyticsUrl: string = `https://sd2.galaxion.space/api/stats/v1/add/`;

    yandexSdk: any;
    yandexPlayer: any;
    yandexPayments: any;
    yandexLeaderboards: any;

    yandexPlayerData: string = "";
    yandexPurchasesData: string = "";
    yandexLeaderboardData: string = "";
    yandexRewardedVideoStatus: string = "reset";

    async LiteAuth() {
        this.yandexPlayer = await this.yandexSdk.getPlayer({scopes: true});
        console.warn("[YandexProvider] player id: ", this.yandexPlayer.getUniqueID())
    }

    async FullAuth() {
        if (!this.yandexPlayer)
            await this.LiteAuth()

        if (this.yandexPlayer.getMode() === 'lite') {
            await this.yandexSdk.auth.openAuthDialog();
            this.yandexPlayer = await this.yandexSdk.getPlayer({scopes: true});
        }
    }

    ConfirmReady(): void {
        if (this.yandexSdk.features.LoadingAPI != null) {
            this.yandexSdk.features.LoadingAPI.ready();
        }
    }

    GameplayResume(): void {
    }

    GameplayPaused(): void {
    }

    Congratulations(): void {
    }

    async Initialize() {
        // @ts-ignore
        this.yandexSdk = await YaGames.init();
        console.warn("[YandexProvider] Initialize");
    }

    IsAuthorized(): boolean {
        console.warn("[YandexProvider] IsAuthorized:" + !!this.yandexPlayer);
        return !!this.yandexPlayer;
    }

    GetUniquePlayerId(): string {
        return this.yandexPlayer.getUniqueID() || "";
    }

    private _dataFetched: boolean = false;

    async FetchPlayerData() {
        console.warn("[YandexProvider] FetchPlayerData start");
        let data = await this.yandexPlayer.getData();
        this.yandexPlayerData = JSON.stringify(data);
        this._dataFetched = true;
        console.warn("[YandexProvider] FetchPlayerData completed");
    }

    IsPlayerDataFetched() {
        return this._dataFetched;
    }

    GetPlayerData(): string {
        console.warn("[YandexProvider] GetPlayerData:" + this.yandexPlayerData);
        return this.yandexPlayerData || "";
    }

    async SetPlayerData(data: string) {
        let parsed = JSON.parse(data);
        await this.yandexPlayer.setData(parsed, true);
        console.warn("[YandexProvider] SetPlayerData");
    }

    GetLanguage(): string {
        return this.yandexSdk.environment.i18n.lang || "en";
    }

    async InitializeIAPs() {
        this.yandexPayments = await this.yandexSdk.getPayments({signed: true});
        console.warn("[YandexProvider] InitializeIAPs");
    }

    IsActiveIAPs(): boolean {
        console.warn("[YandexProvider] IsActiveIAPs:" + !!this.yandexSdk);
        return !!this.yandexSdk;
    }

    async FetchPurchases() {
        this.yandexPurchasesData = "";

        let purchases = await this.yandexPayments.getPurchases();

        let purchasesObject: any = {
            'purchases': []
        }

        purchases.forEach(
            (purchase: any) => {
                purchasesObject['purchases'].push(purchase);
            }
        );

        this.yandexPurchasesData = JSON.stringify(purchasesObject);
        console.warn("[YandexProvider] FetchPurchases");
    }

    GetPurchases(): string {
        console.warn("[YandexProvider] GetPurchases:" + this.yandexPurchasesData);
        return this.yandexPurchasesData;
    }

    async Purchase(purchaseId: string) {
        console.warn("[YandexProvider] Purchase:" + purchaseId);
        await this.yandexPayments.purchase({id: purchaseId})
    }

    async Consume(token: string) {
        console.warn("[YandexProvider] Consume:" + token);
        await this.yandexPayments.consumePurchase(token);
    }

    async ShowBanner() {
        let status = await this.yandexSdk.adv.getBannerAdvStatus();

        if (status.stickyAdvIsShowing) {
            console.log("Banner already shown");
        } else if (status.reason) {
            console.log("Banner is not shown: " + status.reason);
        } else {
            await this.yandexSdk.adv.showBannerAdv();
        }
    }

    async HideBanner() {
        await this.yandexSdk.adv.hideBannerAdv();
    }

    isRewardForAdConfirmed = false;

    _rewardedVideoConfig = {
        'callbacks': {
            onOpen: () => {
                this.yandexRewardedVideoStatus = 'started';
                console.warn(`status: ${this.yandexRewardedVideoStatus}`);
            },
            onRewarded: () => {
                this.yandexRewardedVideoStatus = 'allowReward';
                console.warn(`status: ${this.yandexRewardedVideoStatus}`);
                this.isRewardForAdConfirmed = true;
            },
            onClose: () => {
                this.yandexRewardedVideoStatus = 'closed';
                console.warn(`status: ${this.yandexRewardedVideoStatus}`);
            },
            onError: (e: any) => {
                this.yandexRewardedVideoStatus = 'error';
                console.warn(`status: ${this.yandexRewardedVideoStatus}`);
            }
        }
    }

    HasRewardedVideo(): boolean {
        return true;
    }

    async FetchRewardedVideo(): Promise<void> {

    }

    async ShowRewardedVideo() {
        this.yandexRewardedVideoStatus = 'reset';
        this.isRewardForAdConfirmed = false;
        await this.yandexSdk.adv.showRewardedVideo(this._rewardedVideoConfig);
    }

    GetRewardedVideoStatus() {
        return this.yandexRewardedVideoStatus;
    }

    IsRewardForAdConfirmed() {
        return this.isRewardForAdConfirmed;
    }

    // Interstitial ads
    HasInterstitialVideo(): boolean {
        return false;
    }

    async FetchInterstitialVideo(): Promise<void> {
    }

    async ShowInterstitialVideo(): Promise<void> {
    }

    GetInterstitialVideoStatus(): string {
        return "reset";
    }

    async InitializeLeaderboards() {
        this.yandexSdk.isAvailableMethod('leaderboards.setLeaderboardScore').then(
            this.yandexLeaderboards = this.yandexSdk.getLeaderboards
        );
    }

    IsActiveLeaderboards(): boolean {
        return !!this.yandexLeaderboards;
    }

    async FetchLeaderboard(board: string) {
        this.yandexLeaderboardData = "";

        let config = {'includeUser': true, 'quantityAround': 1, 'quantityTop': 8};

        try {
            let entries = this.yandexLeaderboards.getLeaderboardEntries(board, config);
            let parsed = this._universal.ParseYandexLeaderboard(entries);
            this.yandexLeaderboardData = JSON.stringify(parsed)
        } catch (e) {
            let fakeEntries = this._universal.FakeFetchLeaderBoards(e);
            let parsed = this._universal.ParseYandexLeaderboard(fakeEntries);
            this.yandexLeaderboardData = JSON.stringify(parsed)
        }
    }

    GetLeaderboard(): string {
        return this.yandexLeaderboardData;
    }

    async SetLeaderboardScore(request: LeaderboardRequest) {
        if (this.yandexLeaderboards && this.yandexLeaderboards.initialized)
            this.yandexLeaderboards.setLeaderboardScore(request.title, request.score, null)
    }

    // Social
    IsActiveFriends() {
        return false;
    }

    async FriendHelpRequest(text: string): Promise<void> {
        throw new Error("Not implemented");
    }

    IsTextPublished(): boolean {
        return false;
    }

    IsPublishTextResult(): boolean {
        return false;
    }

    ResetPublishedText(): void {
        throw new Error("Not implemented");
    }

    ResetPublishTextResult(): void {
        throw new Error("Not implemented");
    }

    async PublishText(text: string, attachment: string): Promise<void> {
        throw new Error("Not implemented");
    }
}