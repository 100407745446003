import Provider, {LeaderboardRequest} from "./Provider";

interface YandexLeaderboardEntry {
    score: number;
    rank: number;
    player: {
        publicName: string
    }
}

interface ParsedLeaderboardEntry {
    score: number,
    rank: number,
    publicName: string
}

export default class Universal implements Provider {
    // Provider for pure html game, without distribution portal
    SupportScreenHeightBaking(): boolean {
        return false;
    }
    BottomOffsetForBanners(): number {
        return 0;
    }

    // game loading
    LoadingStart(): void {

    }
    LoadingStop(): void {

    }


    GetPlatform(): string {
        return "universal";
    }

    _playerId: string | undefined;

    SetPlayerId( playerId: string ) {
        console.warn("[UnityApp] SetPlayerId")
        this._playerId = playerId;
    }

    ParseYandexLeaderboard(response:
         {
             entries: YandexLeaderboardEntry[];
             userRank: number
         }
    ) {
        var parsed: {
            entries: ParsedLeaderboardEntry[],
            rank: number
        } = {
            entries: [],
            rank: response.userRank
        }

        response.entries.forEach(
            (entry) => {
                parsed.entries.push( { 'score': entry.score, 'rank': entry.rank, 'publicName': entry.player.publicName } );
            }
        );

        return parsed;
    }

    FakeFetchLeaderBoards(err: any) {
        var fakeResponse = {
            'userRank': 0,
            'entries': [
                {
                    'score': 0,
                    'rank': 0,
                    'player': {
                        'publicName': 'Unauthorized'
                    }
                }
            ]
        };

        return fakeResponse;
    }
    // =================================================================================================================

    // call from js before unity app loaded
    async Initialize(): Promise<void> {
        console.warn("[UnityApp] Initialize")
    }
    async InitializeIAPs(): Promise<void> {
        console.warn("[UnityApp] InitializeIAPs")
    }
    async InitializeLeaderboards(): Promise<void> {
        console.warn("[UnityApp] InitializeLeaderboards")
    }

    // call from unity
    ConfirmReady() {
        console.warn("[UnityApp] ConfirmReady")
    }
    GameplayResume(): void {}
    GameplayPaused(): void {}
    Congratulations(): void {}

    // auth
    async LiteAuth(): Promise<void> {
        let r = (Math.random() + 1).toString(36).substring(7);
        localStorage.setItem("_playerUniqueId", r );
        console.warn("[UnityApp] LiteAuth")
    }
    async FullAuth(): Promise<void> {
        throw new Error("Not implemented");
    }
    IsAuthorized(): boolean {
        console.warn("[UnityApp] IsAuthorized")
        return true;
    }
    GetUniquePlayerId(): string {
        console.warn("[UnityApp] GetUniquePlayerId")
        var r = localStorage.getItem("_playerUniqueId" );

        if ( r != null )
            return r;
        else {
            r = (Math.random() + 1).toString(36).substring(7);
            localStorage.setItem("_playerUniqueId", r);
            return r;
        }
    }

    // profile data
    async FetchPlayerData(): Promise<void> {

    }

    IsPlayerDataFetched() {
        return true;
    }

    GetPlayerData(): string {
        let data = localStorage.getItem("_userProfile");

        if ( !!data )
            return data;
        else
            return ""
    }
    async SetPlayerData(data: string): Promise<void> {
        localStorage.setItem("_userProfile", data);
    }

    // preferences
    GetLanguage(): string {
        return "ru";
    }

    // IAPs
    IsActiveIAPs(): boolean {
        return true;
    }
    async FetchPurchases(): Promise<void> {

    }
    GetPurchases(): string {
        let purchasesObject: any = {
            'purchases': []
        }

        return JSON.stringify(purchasesObject);
    }
    async Purchase(purchaseId: string): Promise<void> {

    }
    async Consume(token: string): Promise<void> {

    }

    // Banner ads
    async ShowBanner(): Promise<void> {

    }
    async HideBanner(): Promise<void> {

    }

    // Rewarded ads
    HasRewardedVideo(): boolean {
        return false;
    }

    async FetchRewardedVideo(): Promise<void> {

    }

    async ShowRewardedVideo(): Promise<void> {

    }
    GetRewardedVideoStatus(): string {
        return "reset";
    }

    IsRewardForAdConfirmed(): boolean {
        return false;
    }

    // Interstitial ads
    HasInterstitialVideo(): boolean {
        return false;
    }
    async FetchInterstitialVideo(): Promise<void> {}
    async ShowInterstitialVideo(): Promise<void> {}
    GetInterstitialVideoStatus(): string {
        return "reset";
    }

    // Leaderboards
    IsActiveLeaderboards(): boolean {
        return true;
    }
    async FetchLeaderboard(board: string): Promise<void> {

    }
    GetLeaderboard(): string {
        let fakeEntries = this.FakeFetchLeaderBoards(null);
        let data = this.ParseYandexLeaderboard( fakeEntries );
        return JSON.stringify(data);
    }
    async SetLeaderboardScore(request: LeaderboardRequest): Promise<void> {

    }

    // Social
    IsActiveFriends() {
        return false;
    }

    async FriendHelpRequest(text: string): Promise<void> {
        throw new Error("Not implemented");
    }

    IsTextPublished(): boolean {
        return false;
    }
    IsPublishTextResult(): boolean {
        return false;
    }
    ResetPublishedText(): void {
        throw new Error("Not implemented");
    }
    ResetPublishTextResult(): void {
        throw new Error("Not implemented");
    }
    async PublishText( text: string, attachment: string ): Promise<void> {
        throw new Error("Not implemented");
    }
}