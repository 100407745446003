import Provider, {LeaderboardRequest} from "./Provider";
import bridge, {BannerAdLocation, EAdsFormats} from '@vkontakte/vk-bridge';
import Universal from "./Universal";

export default class VK implements Provider {
    SupportScreenHeightBaking(): boolean {
        return true;
    }

    BottomOffsetForBanners(): number {
        return 106;
    }

    // game loading
    LoadingStart(): void {}
    LoadingStop(): void {}

    GetPlatform(): string {
        return "vk";
    }

    _playerId: string = "";
    _universal: Universal = new Universal();
    _initialized = false;

    async Initialize(): Promise<void> {
        await bridge.send("VKWebAppInit", {});
        this._initialized = true;
    }

    async InitializeIAPs(): Promise<void> {}

    async InitializeLeaderboards(): Promise<void> {}

    async LiteAuth() {
        let data = await bridge.send("VKWebAppGetUserInfo");
        this._playerId = data.id.toString();
        this._universal.SetPlayerId(this._playerId);
    }

    // call from unity

    // auth
    ConfirmReady() {}

    GameplayResume(): void {}
    GameplayPaused(): void {}
    Congratulations(): void {}

    async FullAuth() {
        await this.LiteAuth();
    }
    IsAuthorized() {
        return !!this._playerId;
    }
    GetUniquePlayerId() {
        return this._playerId;
    }

    // profile data
    async FetchPlayerData(): Promise<void> {
        await this._universal.FetchPlayerData();
    }

    IsPlayerDataFetched() {
        return this._universal.IsPlayerDataFetched();
    }

    GetPlayerData(): string {
        return this._universal.GetPlayerData();
    }

    async SetPlayerData(data: string): Promise<void> {
        await this._universal.SetPlayerData(data);
    }

    // preferences
    GetLanguage(): string {
        return "ru";
    }

    // IAPs
    IsActiveIAPs(): boolean {
        return this._initialized;
    }

    async FetchPurchases(): Promise<void> {}
    GetPurchases(): string {
        return this._universal.GetPurchases();
    }
    async Purchase(purchaseId: string): Promise<void> {}
    async Consume(token: string): Promise<void> {}

    async _showVKBanner() {
        try {
            await bridge.send('VKWebAppShowBannerAd', {'banner_location': BannerAdLocation.BOTTOM})
        }
        catch (e) {

        }
    }

    // Banner ads
    async ShowBanner(): Promise<void> {
        try {
            let show = await bridge.send('VKWebAppCheckBannerAd');
            if (!show.result)
                await this._showVKBanner();
        }
        catch (e) {
            await this._showVKBanner();
        }
    }
    async HideBanner(): Promise<void> {
        try {
            await bridge.send('VKWebAppHideBannerAd');
        }
        catch (e) {

        }
    }

    // Rewarded ads
    _hasRewardedVideo: boolean = false;
    _rewardedVideoStatus: string = "reset";

    HasRewardedVideo(): boolean {
        return this._hasRewardedVideo;
    }

    async FetchRewardedVideo(): Promise<void> {
        try {
            let result = await bridge.send('VKWebAppCheckNativeAds', { ad_format: EAdsFormats.REWARD, use_waterfall: true })

            this._hasRewardedVideo = result.result;
        }
        catch (e) {}
    }

    async ShowRewardedVideo(): Promise<void> {
        if ( this._hasRewardedVideo ) {
            this._rewardedVideoStatus = "started";

            try {
                let result = await bridge.send('VKWebAppShowNativeAds', { ad_format: EAdsFormats.REWARD, use_waterfall: true })

                if ( result.result ) {
                    this._rewardedVideoStatus = "allowReward";
                    this._hasRewardedVideo = false;
                }
                else {
                    this._rewardedVideoStatus = "error";
                    this._hasRewardedVideo = false;
                }
            }
            catch (e) {
                this._rewardedVideoStatus = "error";
                this._hasRewardedVideo = false;
            }
        }
    }

    GetRewardedVideoStatus(): string {
        return this._rewardedVideoStatus;
    }

    IsRewardForAdConfirmed(): boolean {
        return this._universal.IsRewardForAdConfirmed();
    }

    // Interstitial ads
    HasInterstitialVideo(): boolean {
        return false;
    }
    async FetchInterstitialVideo(): Promise<void> {}
    async ShowInterstitialVideo(): Promise<void> {}
    GetInterstitialVideoStatus(): string {
        return "reset";
    }

    // Leaderboards
    IsActiveLeaderboards(): boolean {
        return !!this._playerId;
    }
    async FetchLeaderboard(board: string): Promise<void> {}
    GetLeaderboard(): string {
        let fakeEntries = this._universal.FakeFetchLeaderBoards(null);
        let data = this._universal.ParseYandexLeaderboard( fakeEntries );
        return JSON.stringify(data);
    }
    async SetLeaderboardScore(request: LeaderboardRequest): Promise<void> {
        // works only on vk-direct-games
        try {
            await bridge.send("VKWebAppShowLeaderBoardBox", {user_result: request.score});
        }
        catch (e) {
            console.error(e);
        }
    }

    // Social
    IsActiveFriends() {
        return true;
    }

    async FriendHelpRequest(text: string): Promise<void> {

    }

    _textIsPublished: boolean = false;
    _textIsPublishResult: boolean = false;

    IsTextPublished(): boolean {
        return this._textIsPublished;
    }
    IsPublishTextResult(): boolean {
        return this._textIsPublishResult;
    }
    ResetPublishedText(): void {
        this._textIsPublished = false;
    }
    ResetPublishTextResult(): void {
        this._textIsPublishResult = false;
    }
    async PublishText( text: string, attachment: string ): Promise<void> {
        try {
            let result = await bridge.send('VKWebAppShowWallPostBox', {message: text, attachments: attachment, owner_id: parseInt( this.GetUniquePlayerId() ) });
            this._textIsPublished = !!result.post_id;
            this._textIsPublishResult = true;
        }
        catch (e) {
            this._textIsPublishResult = true;
        }
    }
}