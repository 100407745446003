import React from "react";
import {useParams} from "react-router";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import UnityAdmin from "./UnityAdmin";
import './UnityCanvas.css';
import {Store} from "effector";
import Provider from "../providers/Provider";

interface UnityAdminRelatedProps {
    $progress: Store<number>
    $coverIsActive: Store<boolean>
}

interface CoverState {
    coverIsActive:boolean
}

interface ProgressBarState {
    progress: number
}

class ProgressBar extends React.Component<UnityAdminRelatedProps, ProgressBarState>  {
    constructor(props: UnityAdminRelatedProps) {
        super(props);
        this.state = {
            progress: 0,
        }
    }

    componentDidMount() {
        this.props.$progress.watch(
            (value) => {
                this.setState({progress: value})
            }
        )
    }

    render() {
        return (
            <div id="unity-progress-wrapper">
                <div id="unity-progress-title">Space Dangers</div>
                <img id="unity-progress-logo" src={"/spaceDangers/images/iconFake278.png"}></img>
                <div id="unity-progress-bar-bg"></div>
                <div id="unity-progress-bar" style={{width: this.state.progress * 150}}></div>
                <div id="unity-progress-text">{Math.round( this.state.progress * 100 )}%</div>
            </div>
        )
    }
}

class Cover extends React.Component<UnityAdminRelatedProps, CoverState>  {
    constructor(props: UnityAdminRelatedProps) {
        super(props);
        this.state = {
            coverIsActive: true
        }
    }

    componentDidMount() {
        this.props.$coverIsActive.watch(
            (value) => {
                this.setState({coverIsActive: value})
            }
        )
    }

    render() {
        if ( this.state.coverIsActive ) {
            return (
                <div id="logo-container">
                    <ProgressBar $progress={this.props.$progress} $coverIsActive={this.props.$coverIsActive}/>
                </div>
            )
        }

        return null;
    }
}

class Loader extends React.Component<{gameName: string | undefined, provider: Provider}, any> {
    public componentDidMount() {
        window.ua = new UnityAdmin( this.props.provider );
        window.ua.Initialize(this.props.gameName || "").then(
            () => this.setState({})
        );
    }

    public render() {
        if ( !window.ua ) {
            return null;
        }

        return (
            <div id="game-wrapper">
                <UnityCanvas provider={this.props.provider}/>
                <Cover $progress={window.ua.$coverProgress} $coverIsActive={window.ua.$coverIsActive}/>
            </div>
        )
    }
}


interface UnityCanvasProps {
    provider: Provider
}

interface UnityCanvasState {
    className: string
}

class UnityCanvas extends React.Component<UnityCanvasProps, UnityCanvasState> {
    constructor(props: UnityCanvasProps) {
        super(props);
        this.state = {
            className: this.CalculateClass()
        }
    }

    private GetSearchParameter( name: string ) {
        let sp = new URLSearchParams(window.location.search);
        return sp.get(name) || "desktop";
    }

    private CalculateClass() {
        // mobile
        if ( /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || this.GetSearchParameter('forceResolution') === 'mobile' ) {
            if ( window.innerHeight * 1080 / 1920 < window.innerWidth ) {
                return "mobile-height";
            }
            else {
                return "mobile-width";
            }
        }
        else {
            if ( window.innerHeight * 1920 / 1080 < window.innerWidth ) {
                return "desktop-height";
            }
            else {
                return "desktop-width";
            }
        }
    }

    _backedHeight= 0;
    _lastScreenHeight: number = 0;

    private _BakeHeight(): void {
        this._backedHeight = window.innerHeight - this.props.provider.BottomOffsetForBanners();

        if ( this.props.provider.SupportScreenHeightBaking() )
            this._lastScreenHeight = window.screen.height;
    }

    private _OnResize() {
        console.warn("Resize for platform: " + this.props.provider.GetPlatform() )

        if ( this._lastScreenHeight !== window.screen.height ) {
            this._BakeHeight();
        }

        this.setState(
            {
                className: this.CalculateClass()
            }
        );
    }

    public componentDidMount() {
        this._BakeHeight();
        this.setState(
            {
                className: this.CalculateClass()
            }
        );

        window.addEventListener(
            'resize',
            () => this._OnResize(),
            false
        );
    }

    public render() {
        return (
            <canvas id="unity-canvas" ref={window.ua.UnityCanvasRef} style={{width: "100%", height: "100%"}}></canvas>
        )
    }
}

export {Loader, UnityCanvas}