import Provider from "../providers/Provider";
import React from "react";

interface GameSDKProps {
    provider: Provider;
    children: any;
}

export default class GameSDK extends React.Component<GameSDKProps, any> {
    private _sdkPath: string = "";
    private _isLoaded: boolean = false;

    public AttachScript() {
        if ( this.props.provider.GetPlatform() === "exe.ru" ) {
            this._sdkPath = "https://exe.ru/assets/js/api.js"
        }
        else if ( this.props.provider.GetPlatform() === "yandex" ) {
            this._sdkPath = "https://yandex.ru/games/sdk/v2"
        }
        else if ( this.props.provider.GetPlatform() === "telegram" ) {
            this._sdkPath = "https://telegram.org/js/games.js"
        }
        else if ( this.props.provider.GetPlatform() === "crazygames" ) {
            this._sdkPath = "https://sdk.crazygames.com/crazygames-sdk-v2.js"
        }

        if ( this._sdkPath === "" ) {
            this.OnLoadScript();
            return;
        }

        let sdkScript = document.querySelector("#sdk-script");

        if ( sdkScript === null ) {
            const script = document.createElement('script');
            script.src = this._sdkPath;
            script.id = "sdk-script";
            script.type = "application/javascript";
            script.async = false;
            script.addEventListener("load", () => this.OnLoadScript());
            document.body.appendChild(script);
        }
    }

    componentDidMount() {
        this.AttachScript();
    }

    private async _InitializeProvider(): Promise<void> {
        console.warn('[Initialize provider] start');
        await this.props.provider.Initialize();
        console.warn('[Initialize provider] initialized');
        await this.props.provider.LiteAuth();
        console.warn('[Initialize provider] lite authorized');
        await this.props.provider.InitializeIAPs();
        console.warn('[Initialize provider] IAPs initialized');
        await this.props.provider.InitializeLeaderboards();
        console.warn('[Initialize provider] Leaderboards initialized');
        await this.props.provider.FetchRewardedVideo();
        console.warn('[Initialize provider] Rewarded video fetched');
        console.warn('[Initialize provider] end');
    }

    OnLoadScript() {
        this._InitializeProvider().then(
            () => {
                this._isLoaded = true;
                this.setState({})
            }
        );
    }

    render() {
        if ( !this._isLoaded ) {
            return null;
        }

        if ( this.props.provider.GetPlatform() === "crazygames" && window.innerHeight >= window.innerWidth ) {
            return (
                <div style={{width: "100%", height: "100%"}}>
                    <div style={{width: "100%", height: "calc(100% - 90px)", position: "absolute", top: "0"}}>
                        {this.props.children}
                    </div>
                    <div id="responsive-banner-container" style={{width: "100%", height: "90px", position: "absolute", bottom: "0"}}></div>
                </div>
            )
        }
        if ( this.props.provider.GetPlatform() === "crazygames" ) {
            return (
                <div style={{width: "100%", height: "100%"}}>
                    <div style={{width: "calc(100% - 160px)", height: "100%", position: "absolute", left: "0"}}>
                        {this.props.children}
                    </div>
                    <div id="responsive-banner-container" style={{width: "160px", height: "100%", position: "absolute", right: "0"}}></div>
                </div>
            )
        }
        else {
            return (
                <div style={{width: "100%", height: "100%"}}>
                    {this.props.children}
                </div>
            )
        }
    }
}